import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { v4 as uuidv4 } from "uuid";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import Status from "../../Status";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function ListadoCortes() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_CORTES = process.env.REACT_APP_URL_CORTES;
  const URL_ARTICULOS_CORTES = process.env.REACT_APP_URL_ARTICULOS_CORTES;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_PROCESOS_SALIDA = process.env.REACT_APP_URL_PROCESOS_SALIDA;
  const URL_ARTICULOS_PROCESOS_SALIDA =
    process.env.REACT_APP_URL_ARTICULOS_PROCESOS_SALIDA;
  const URL_PROCESOS_ENTRADA = process.env.REACT_APP_URL_PROCESOS_ENTRADA;
  const URL_ARTICULOS_PROCESOS_ENTRADA =
    process.env.REACT_APP_URL_ARTICULOS_PROCESOS_ENTRADA;
  const URL_NOTAS_CXP = process.env.REACT_APP_URL_NOTAS_CXP;
  const URL_HABILITACION = process.env.REACT_APP_URL_HABILITACION;
  const URL_HABILITACION_CORTES = process.env.REACT_APP_URL_HABILITACION_CORTES;
  const URL_DETALLE_HABILITACION_CORTES =
    process.env.REACT_APP_URL_DETALLE_HABILITACION_CORTES;
  const URL_FALTANTES_PROVEEDORES =
    process.env.REACT_APP_URL_FALTANTES_PROVEEDORES;
  const URL_ENTRADAS_ALMACEN = process.env.REACT_APP_URL_ENTRADAS_ALMACEN;
  const URL_ENTRADAS_ALMACEN_ACUMULADOS =
    process.env.REACT_APP_URL_ENTRADAS_ALMACEN_ACUMULADOS;
  const ALMACEN = process.env.REACT_APP_ALMACEN;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_LINEAS = process.env.REACT_APP_URL_LINEAS;
  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-90);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [cortes, setCortes] = useState([]);

  const [articulos, setArticulos] = useState([]);
  const [areas, setAreas] = useState([]);
  const [areasEntrada, setAreasEntrada] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [selectedCorte, setSelectedCorte] = useState("");
  const [selectedCorteNumero, setSelectedCorteNumero] = useState("");
  const [modalProcesosSalida, setModalProcesosSalida] = useState(false);
  const toggleProcesosSalida = () =>
    setModalProcesosSalida(!modalProcesosSalida);
  const [articulosCortes, setArticulosCortes] = useState([]);
  const [articulosProcesosSalida, setArticulosProcesosSalida] = useState([]);
  const [articulosUnicos, setArticulosUnicos] = useState([]);
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [idCatalogoProcesos, setIdCatalogoProcesos] = useState("");
  const [nombreProcesosSalida, setNombreProcesosSalida] = useState("");
  const [idArticulo, setIdArticulo] = useState("");
  const [nombreArticulo, setNombreArticulo] = useState("");
  const [idPedido, setIdPedido] = useState("");
  const [numeroPedido, setNumeroPedido] = useState("");
  const [modalFuncionProcesosSalida, setModalFuncionProcesosSalida] =
    useState(false);
  const toggleFuncionProcesosSalida = () =>
    setModalFuncionProcesosSalida(!modalFuncionProcesosSalida);
  const [fechaProceso, setFechaProceso] = useState("");
  const [fechaCompromisoProceso, setFechaCompromisoProceso] = useState("");
  const [maquileroProceso, setMaquileroProceso] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [precioProceso, setPrecioProceso] = useState(0);
  const [proveedores, setProveedores] = useState([]);
  const [totalPiezas, setTotalPiezas] = useState(0);
  const [totalGeneral, setTotalGeneral] = useState(0);

  const [validaBoton, setValidaBoton] = useState(true);

  const [fecha, setFecha] = useState("");
  const [plazo, setPlazo] = useState("");

  const [numero_corte, setNumeroCorte] = useState("");

  const [idEdit, setIdEdit] = useState("");

  const [modalEditArticulos, setModalEditArticulos] = useState(false);
  const toggleEditArticulos = () => setModalEditArticulos(!modalEditArticulos);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [articulosEntrada, setArticulosEntrada] = useState([]);
  const [articulosUnicosEntrada, setArticulosUnicosEntrada] = useState([]);
  const [nombreProcesosEntrada, setNombreProcesosEntrada] = useState("");
  const [articulosProcesosEntrada, setArticulosProcesosEntrada] = useState([]);
  const [selectedProcesoSalida, setSelectedProcesoSalida] = useState("");

  const [modalProcesosEntrada, setModalProcesosEntrada] = useState(false);
  const toggleProcesosEntrada = () =>
    setModalProcesosEntrada(!modalProcesosEntrada);
  const [modalFuncionProcesosEntrada, setModalFuncionProcesosEntrada] =
    useState(false);
  const toggleFuncionProcesosEntrada = () =>
    setModalFuncionProcesosEntrada(!modalFuncionProcesosEntrada);

  const [entregaHabilitacion, setEntregaHabilitacion] = useState(false);
  const [habilitacion, setHabilitacion] = useState([]);

  const [modalFaltante, setModalFaltante] = useState(false);
  const toggleFaltante = () => setModalFaltante(!modalFaltante);
  const [precioFaltante, setPrecioFaltante] = useState(0);
  const [cantidadFaltante, setCantidadFaltante] = useState(0);
  const [totalFaltante, setTotalFaltante] = useState(0);
  const [procesoSalidaFaltante, setProcesoSalidaFaltante] = useState("");
  const [idMaquileroFaltante, setIdMaquileroFaltante] = useState("");
  const [fechaFaltante, setFechaFaltante] = useState("");

  const [articuloProceso, setArticuloProceso] = useState("");

  const [modalEntradaAlmacen, setModalEntradaAlmacen] = useState(false);
  const toggleEntradaAlmacen = () =>
    setModalEntradaAlmacen(!modalEntradaAlmacen);
  const [articulosCortesEntrada, setArticulosCortesEntrada] = useState([]);
  const [articulosUnicosEntradaAlma, setArticulosUnicosEntradaAlma] = useState(
    []
  );
  const [totalPiezasEntradaAlma, setTotalPiezasEntradaAlma] = useState(0);
  const [fechaEntradaAlma, setFechaEntradaAlma] = useState("");

  const [PDFPS, setPDFPS] = useState([]);
  const [modalPDFPS, setModalPDFPS] = useState(false);
  const togglePDFPS = () => setModalPDFPS(!modalPDFPS);

  const [PDFPE, setPDFPE] = useState([]);
  const [modalPDFPE, setModalPDFPE] = useState(false);
  const togglePDFPE = () => setModalPDFPE(!modalPDFPE);

  const [modalHabilitacion, setModalHabilitacion] = useState(false);
  const toggleHabilitacion = () => setModalHabilitacion(!modalHabilitacion);
  const [idProcesosSalidaHab, setIdProcesosSalidaHab] = useState("");
  const [idCorteHab, setIdCorteHab] = useState("");
  const [idPedidoHab, setIdPedidoHab] = useState("");
  const [idProveedorHab, setIdProveedorHab] = useState("");
  const [fechaHab, setFechaHab] = useState(endDate);
  const [totalHabilitacion, setTotalHabilitacion] = useState(0);

  const [selectedProduccion, setSelectedProduccion] = useState("");
  const [lineas, setLineas] = useState([]);
  const [selectedLinea, setSelectedLinea] = useState("");

  const [articulosPSEdit, setArticulosPSEdit] = useState([]);
  const [precio, setPrecio] = useState(0);
  const [modalEditPS, setModalEditPS] = useState(false);
  const toggleEditPS = () => setModalEditPS(!modalEditPS);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      colores: "",
      tallas: "",
      cantidad: 0,
      total: 0,
      articulosPedido: "",
    },
  ]);

  const [inputFieldsHabilitacion, setInputFieldsHabilitacion] = useState([
    {
      id: uuidv4(),
      idHabilitacion: "",
      cantidad: 0,
      precio:0
    },
  ]);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
    .get(`${URL_CORTES}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCortes = response.data;
        // Array para pagination
        let arrayTabla = allCortes
          .sort((a, b) => (a.idCorte > b.idCorte ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              numero: a.idCorte,
              fecha: a.fecha,
              total_cantidad: a.total_cantidad,
              ordenCorte: a.ordenesCorte[0].idOrdenesCorte,
              numeroOP: a.ordenesCorte[0].numeroOP,
              fechaCancelacionPedido: a.pedidos[0].fecha_cancelacion,
              nombreArticulo: a.pedidos[0].articulos[0].articulosPadre[0].codigo + " " + a.pedidos[0].articulos[0].nombre,
              linea: a.pedidos[0].articulos[0].articulosPadre[0].lineas[0].name,
              idLinea: a.pedidos[0].articulos[0].articulosPadre[0].lineas[0]._id,
              mesa: a.mesa,
              lienzos: a.lienzos,
              largoTrazo: a.largoTrazo,
              tiempoInicio: a.tiempoInicio,
              tiempoFin: a.tiempoFin,
              responsable1: a.responsable1[0].nombre + " " + a.responsable1[0].apellido,
              responsable2: a.responsable2[0].nombre + " " + a.responsable2[0].apellido,
              status: a.status,
              produccion: a.produccion
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setCortes(allCortes);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAreas = response.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_LINEAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allLineas = res.data;
        setLineas(allLineas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_HABILITACION, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allHabilitacion = response.data;
        // Array para pagination
        let arrayTabla = allHabilitacion
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              nombre: a.nombre,
              unidad: a.unidad,
              observaciones: a.observaciones,
              descripcion: a.descripcion,
              costo: a.costo,
              is_active: a.is_active
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setHabilitacion(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        return [a.numero, a.fecha, a.ordenCorte, a.total_cantidad];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Produccion`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Orden de Corte", "Cantidad"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`ListadoProduccion.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          OrdenCorte: a.ordenCorte,
          TotalCantidad: a.total_cantidad,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoProduccion";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoProduccion",
        sheetFilter: ["Numero", "Fecha", "OrdenCorte", "TotalCantidad"],
        sheetHeader: ["Numero", "Fecha", "OrdenCorte", "TotalCantidad"],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        return [a.numero, a.fecha, a.ordenCorte, a.total_cantidad];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Produccion`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [["Numero", "Fecha", "Orden de Corte", "Cantidad"]],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Produccion",
          email: mailTo,
          fileName: "ListadoProduccion.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Produccion.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "# OP", field: "numeroOP", sortable: true },
    // { name: "Numero", field: "numero", sortable: true },
    { name: "Articulo", field: "nombreArticulo", sortable: true },
    // { name: "Orden Corte", field: "ordenCorte", sortable: true },
    { name: "Cantidad", field: "total_cantidad", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "PDF", field: "PDF", sortable: false },
    { name: "Salida Procesos", field: "Salida Procesos", sortable: false },
    { name: "Entrada Procesos", field: "Entrada Procesos", sortable: false },
    // { name: "Entrada Almacen", field: "Entrada Almacen", sortable: false },
    { name: "Status", field: "status", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombreArticulo.toLowerCase().includes(search.toLowerCase()) ||
          comment.numero.toString().includes(search) ||
          comment.numeroOP.toString().includes(search) ||
          comment.total_cantidad.toString().includes(search) ||
          comment.ordenCorte.toString().includes(search) 
          // ||
          // comment.mesa.toString().includes(search)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }
    
    if (selectedProduccion) {
      computedComments = computedComments.filter((e) =>
        e.produccion.includes(selectedProduccion)
      );
    }
    if (selectedLinea) {
      computedComments = computedComments.filter((e) =>
        e.idLinea.includes(selectedLinea)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "numero" &&
      sorting.field != "total_cantidad" &&
      sorting.field != "ordenCorte" 
      // &&
      // sorting.field != "mesa"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "numero" ||
        sorting.field == "total_cantidad" ||
        sorting.field == "ordenCorte" 
        // ||
        // sorting.field == "mesa"
        )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "numero" ||
        sorting.field == "total_cantidad" ||
        sorting.field == "ordenCorte" 
        // ||
        // sorting.field == "mesa"
        )
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedFechaInicio,
    selectedFechaFin,
    selectedProduccion, 
    selectedLinea
  ]);

  async function PDFOC(
    numero,
    fecha,
    ordenCorte,
    mesa,
    total_cantidad,
    idPDFOC,
    lienzos,
    largoTrazo,
    tiempoInicio,
    tiempoFin,
    responsable1,
    responsable2,
    numeroOP
  ) {
    await axios
      .get(`${URL_ARTICULOS_CORTES}/corte/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosCorte = response.data;
        let arrayTabla = allArticulosCorte
          .map((a) => {
            return {
              id: a._id,
              articulo:
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0].articulosPadre[0]
                  .codigo +
                " " +
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0].articulosPadre[0]
                  .codigoCliente +
                " " +
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]
                  .nombre +
                " " +
                a.articulosOrdenesCorte[0].articulosPedido[0].colores[0].name +
                " " +
                a.articulosOrdenesCorte[0].articulosPedido[0].tallas[0].name,
              total_cantidad: a.total_cantidad,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const data = arrayTabla.map((a) => {
          return [
            a.articulo,
            new Intl.NumberFormat("en-US").format(a.total_cantidad),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 15, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 45, 15);
        doc.text(`OP #${numeroOP}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        // doc.text(`Numero OP: ${numeroOP}`, 20, 30);
        // doc.text(`Lienzos: ${lienzos} / Largo Trazo: ${largoTrazo} `, 20, 35);
        // doc.text(`Responsables: ${responsable1} / ${responsable2}`, 20, 40);
        // doc.text(`Inicio: ${tiempoInicio} / Fin: ${tiempoFin}`, 20, 45);
        doc.autoTable({
          head: [["Articulo", "Cantidad"]],
          body: dataPDFLimpia,
          startY: 35,
          foot: [
            ["Total", new Intl.NumberFormat("en-US").format(total_cantidad)],
          ],
          showFoot: "lastPage",
        });
        doc.save(`Corte-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function procesosSalida(id, numero) {
    setEntregaHabilitacion(false);
    setInputFieldsHabilitacion([
      {
        id: uuidv4(),
        idHabilitacion: "",
        cantidad: 0,
        precio:0
      },
    ]);
    axios
      .get(`${URL_ARTICULOS_CORTES}/corte/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrdenCorte = response.data;
        let arrayTabla = allArticulosOrdenCorte
          .map((a) => {
            return {
              id: a._id,
              articuloNombre:
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0].articulosPadre[0]
                  .codigo +
                " / " +
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0].articulosPadre[0]
                  .codigoCliente +
                  " / " +
                  a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]
                    .nombre,
              idArticulo:
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]._id,
              total_cantidad: a.total_cantidad,
              catalogoProcesos:
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]
                  .catalogoProcesos,
              idPedido: a.pedidos[0]._id,
              numeroPedido: a.pedidos[0].idPedido,
              articulosPedido:
                a.articulosOrdenesCorte[0].articulosPedido[0]._id,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const data = Object.values(arrayTabla);
        setArticulosCortes(data);

        let agrupado = data.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            total_cantidad: 0,
          };
          groups[val].idArticulo = item.idArticulo;
          groups[val].idPedido = item.idPedido;
          groups[val].numeroPedido = item.numeroPedido;
          groups[val].articuloNombre = item.articuloNombre;
          groups[val].total_cantidad += item.total_cantidad;
          groups[val].catalogoProcesos = item.catalogoProcesos;
          return groups;
        }, []);
        let dataFinalAgru = Object.values(agrupado);
        setArticulosUnicos(dataFinalAgru);
      })
      .catch((err) => {
        console.log(err);
      });

    setSelectedCorte(id);
    setSelectedCorteNumero(numero);
    toggleProcesosSalida();
  }


  function funcionProcesosSalida(
    idCatalogoProcesos,
    nombreProcesosSalida,
    idArticulo,
    nombreArticulo,
    idPedido,
    numeroPedido
  ) {
    setArticuloProceso(idArticulo)
    axios
      .get(`${URL_ARTICULOS_CORTES}/corte/${selectedCorte}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosProcesosSalida = response.data;
        setArticulosProcesosSalida(allArticulosProcesosSalida);

        let arrayInputFields = allArticulosProcesosSalida
          .map((a) => {
            if (
              idArticulo ==
              a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]._id
            ) {
              return {
                id: uuidv4(),
                colores:
                  a.articulosOrdenesCorte[0].articulosPedido[0].colores[0].name,
                tallas: a.articulosOrdenesCorte[0].articulosPedido[0].tallas[0].name,
                cantidad: a.articulosOrdenesCorte[0].cortado,
                total: 0,
                articulosPedido:
                  a.articulosOrdenesCorte[0].articulosPedido[0]._id,
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataIF = Object.values(arrayInputFields);

        setInputFields(dataIF);

        let cantTotal = dataIF.map((c) => parseFloat(c.cantidad));
        let TC = cantTotal.reduce((t, total, index) => t + total, 0);
        setTotalPiezas(TC);
      })
      .catch((err) => {
        console.log(err);
      });

    setIdCatalogoProcesos(idCatalogoProcesos);
    setNombreProcesosSalida(nombreProcesosSalida);
    setIdArticulo(idArticulo);
    setNombreArticulo(nombreArticulo);
    setIdPedido(idPedido);
    setNumeroPedido(numeroPedido);
    setModalProcesosSalida(false);
    toggleFuncionProcesosSalida();
  }

  const handleChangeInputPrecio = (event) => {
    const newInputFields = inputFields.map((i) => {
      // i[event.target.name] = event.target.value;
      i.total = i.cantidad * event.target.value;
      return i;
    });
    setPrecioProceso(event.target.value);
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.total = parseFloat(event.target.value) * parseFloat(precioProceso);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cantTotal = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cantTotal.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TC);

    let impTotal = inputFields.map((c) => parseFloat(c.total));
    let TG = impTotal.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  }

  function ActualizaTotalesHabilitacion() {
    let cantTotal = inputFieldsHabilitacion.map((c) => parseFloat(c.cantidad) * parseFloat(c.precio));
    let TC = cantTotal.reduce((t, total, index) => t + total, 0);

    // let cantTotalEx = articulosPSEdit.map((c) => parseFloat(c.cantidad) * parseFloat(c.precio));
    // let TCEx = cantTotalEx.reduce((t, total, index) => t + total, 0);


    setTotalHabilitacion(TC);
  }

  const saveSalidaProceso = async (event) => {
    event.preventDefault();
    if (fechaProceso != "") {
      setValidaBoton(false);
      toggleProgreso();

      let totalRollos = 0;
      if (entregaHabilitacion) {
        totalRollos = inputFields.length + inputFieldsHabilitacion.length;
      } else {
        totalRollos = inputFields.length;
      }
      try {
        await axios
          .post(
            URL_PROCESOS_SALIDA,
            {
              catalogoProcesos: idCatalogoProcesos,
              proveedores: maquileroProceso,
              pedidos: idPedido,
              fecha: fechaProceso,
              fechaCompromiso: "2000-01-01",
              observaciones,
              status: "Abierto",
              totalGeneral,
              totalPiezas,
              precio: precioProceso,
              cortes: selectedCorte,
              articulos: articuloProceso
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            inputFields.map((a) => {
              if (a.cantidad != 0) {
                axios
                  .post(
                    URL_ARTICULOS_PROCESOS_SALIDA,
                    {
                      procesosSalida: data.data._id,
                      articulosPedido: a.articulosPedido,
                      pedidos: idPedido,
                      cantidadSalida: a.cantidad,
                      cantidadEntrada: 0,
                      faltantes: a.cantidad,
                      cortes: selectedCorte,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Salida de Proceso",
                            detalle: `Pedido ${numeroPedido} Articulo ${articulosCortes[0].articuloNombre}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Salida de Proceso",
                        detalle: `Pedido ${numeroPedido} Articulo ${articulosCortes[0].articuloNombre}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
            setValidaBoton(true);
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  //Procesos Entrada

  function procesosEntrada(id, numero) {
    axios
      .get(`${URL_ARTICULOS_PROCESOS_SALIDA}/corte/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrdenCorte = response.data;
        let arrayTabla = allArticulosOrdenCorte
          .map((a) => {
            return {
              id: a._id,
              articuloNombre:
              a.articulosPedido[0].articulos[0].articulosPadre[0]
              .codigo +
            " / " +
            a.articulosPedido[0].articulos[0].articulosPadre[0]
              .codigoCliente +
              " / " +
              a.articulosPedido[0].articulos[0]
                .nombre,
              idArticulo: a.articulosPedido[0].articulos[0]._id,
              catalogoProcesos: a.procesosSalida[0].catalogoProcesos[0].name,
              idCatalogoProcesos: a.procesosSalida[0].catalogoProcesos[0]._id,
              idPedido: a.pedidos[0]._id,
              numeroPedido: a.pedidos[0].idPedido,
              articulosPedido: a.articulosPedido[0]._id,
              cantidadEntrada: a.cantidadEntrada,
              cantidadSalida: a.cantidadSalida,
              status: a.procesosSalida[0].status,
              fecha: a.procesosSalida[0].fecha,
              fechaCompromiso: a.procesosSalida[0].fechaCompromiso,
              precio: a.procesosSalida[0].precio,
              maquilero: a.procesosSalida[0].proveedores[0].nombre_comercial,
              idMaquilero: a.procesosSalida[0].proveedores[0]._id,
              procesosSalida: a.procesosSalida[0]._id,
              faltantes: a.faltantes,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const data = Object.values(arrayTabla);
        setArticulosEntrada(data);

        let agrupado = data.reduce(function (groups, item) {
          const val = item["idArticulo"] + item["procesosSalida"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            procesosSalida: item.procesosSalida,
            cantidadSalida: 0,
            cantidadEntrada: 0,
            faltantes: 0,
          };
          groups[val].idArticulo = item.idArticulo;
          groups[val].idPedido = item.idPedido;
          groups[val].numeroPedido = item.numeroPedido;
          groups[val].articuloNombre = item.articuloNombre;
          groups[val].cantidadSalida += item.cantidadSalida;
          groups[val].cantidadEntrada += item.cantidadEntrada;
          groups[val].catalogoProcesos = item.catalogoProcesos;
          groups[val].idCatalogoProcesos = item.idCatalogoProcesos;
          groups[val].status = item.status;
          groups[val].fecha = item.fecha;
          groups[val].fechaCompromiso = item.fechaCompromiso;
          groups[val].precio = item.precio;
          groups[val].maquilero = item.maquilero;
          groups[val].idMaquilero = item.idMaquilero;
          groups[val].procesosSalida = item.procesosSalida;
          groups[val].faltantes += item.faltantes;
          return groups;
        }, []);
        let dataFinalAgru = Object.values(agrupado);
        setArticulosUnicosEntrada(dataFinalAgru);
      })
      .catch((err) => {
        console.log(err);
      });

    setSelectedCorte(id);
    setSelectedCorteNumero(numero);
    toggleProcesosEntrada();
  }

  function funcionProcesosEntrada(
    idCatalogoProcesos,
    nombreProcesosEntrada,
    idArticulo,
    nombreArticulo,
    idPedido,
    numeroPedido,
    procesosSalida
  ) {
    axios
      .get(`${URL_ARTICULOS_PROCESOS_SALIDA}/proceso/${procesosSalida}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosProcesosEntrada = response.data;
        setArticulosProcesosEntrada(allArticulosProcesosEntrada);
        setMaquileroProceso(
          allArticulosProcesosEntrada[0].procesosSalida[0].proveedores[0]._id
        );
        setPrecioProceso(
          allArticulosProcesosEntrada[0].procesosSalida[0].precio
        );

        let arrayInputFields = allArticulosProcesosEntrada.map((a) => {
          return {
            id: uuidv4(),
            colores: a.articulosPedido[0].colores[0].name,
            tallas: a.articulosPedido[0].tallas[0].name,
            cantidad: 0,
            total: 0,
            articulosPedido: a.articulosPedido[0]._id,
            articulosProcesosSalida: a._id,
          };
        });

        let dataIF = Object.values(arrayInputFields);

        setInputFields(dataIF);

        let cantTotal = dataIF.map((c) => parseFloat(c.cantidad));
        let TC = cantTotal.reduce((t, total, index) => t + total, 0);
        setTotalPiezas(TC);

        let impTotal = dataIF.map((c) => parseFloat(c.total));
        let TG = impTotal.reduce((t, total, index) => t + total, 0);
        setTotalGeneral(TG);
      })
      .catch((err) => {
        console.log(err);
      });

    setSelectedProcesoSalida(procesosSalida);
    setIdCatalogoProcesos(idCatalogoProcesos);
    setNombreProcesosEntrada(nombreProcesosEntrada);
    setIdArticulo(idArticulo);
    setNombreArticulo(nombreArticulo);
    setIdPedido(idPedido);
    setNumeroPedido(numeroPedido);
    setModalProcesosEntrada(false);
    toggleFuncionProcesosEntrada();
  }

  const saveEntradaProceso = async (event) => {
    event.preventDefault();
    if (fechaProceso != "") {
      setValidaBoton(false);
      toggleProgreso();

      let totalRollos = inputFields.length;
      try {
        await axios
          .post(
            URL_PROCESOS_ENTRADA,
            {
              catalogoProcesos: idCatalogoProcesos,
              proveedores: maquileroProceso,
              pedidos: idPedido,
              fecha: fechaProceso,
              observaciones,
              totalGeneral,
              totalPiezas,
              precio: precioProceso,
              cortes: selectedCorte,
              procesosSalida: selectedProcesoSalida,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            axios.post(
              URL_NOTAS_CXP,
              {
                procesosEntrada: data.data._id,
                proveedores: maquileroProceso,
                total_cantidad: totalPiezas,
                total_costo: totalGeneral,
                saldo: totalGeneral,
                fecha: fechaProceso
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );

            inputFields.map((a) => {
              if (a.cantidad != 0) {
                axios
                  .post(
                    URL_ARTICULOS_PROCESOS_ENTRADA,
                    {
                      procesosEntrada: data.data._id,
                      articulosPedido: a.articulosPedido,
                      articulosProcesosSalida: a.articulosProcesosSalida,
                      pedidos: idPedido,
                      cantidadEntrada: a.cantidad,
                      cortes: selectedCorte,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Entrada de Proceso",
                            detalle: `Pedido ${numeroPedido}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Entrada de Proceso",
                        detalle: `Pedido ${numeroPedido}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  function EditArticulos(
    id,
    numero,
    catalogosProcesosName,
    proveedoresName,
    idProveedor,
    pedido,
    idPedido,
    fechaCompromiso,
    totalPiezas,
    precio,
    totalGeneral,
    fecha,
    idCorte,
    numeroCorte
  ) {
    setIdProcesosSalidaHab(id);
    setIdCorteHab(idCorte);
    setIdPedidoHab(idPedido);
    setIdProveedorHab(idProveedor);
    setNumeroCorte(numero);
    setPrecio(precio);
    setTotalPiezas(totalPiezas);
    setArticulosPSEdit([])

    axios
      .get(`${URL_ARTICULOS_PROCESOS_SALIDA}/proceso/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPSEdit = response.data;
        let arrayTabla = allArticulosPSEdit
          .map((a) => {
            return {
              id: a._id,
              articulosPedido: a.articulosPedido[0]._id,
              cantidadSalida: a.cantidadSalida,
              talla: a.articulosPedido[0].tallas[0].name,
              color: a.articulosPedido[0].colores[0].name,
              articulo: a.articulosPedido[0].articulos[0].articulosPadre[0]
              .codigo +
            " / " +
            a.articulosPedido[0].articulos[0].articulosPadre[0]
              .codigoCliente +
              " / " +
              a.articulosPedido[0].articulos[0]
                .nombre,
              precio: a.procesosSalida[0].precio,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setArticulosPSEdit(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });
      toggleEditPS();
  }

  const handleChangeInputExistentesCantidad = (id, event) => {
    const newArticulosOrden = articulosPSEdit.map((i) => {
      if (id === i.id) {
        i.cantidadSalida = event.target.value;
      }
      return i;
    });
    setArticulosPSEdit(newArticulosOrden);
    ActualizaTotalesEditPS();
  };

  function ActualizaTotalesEditPS() {
    let precioEditado = precio
    let cantTotal = articulosPSEdit.map((c) => parseFloat(c.cantidadSalida));
    let TC = cantTotal.reduce((t, total, index) => t + total, 0);
    setTotalPiezas(TC);
    setTotalGeneral(TC * precioEditado);
  }

  const saveEditArticulos = async (event) => {
    event.preventDefault();
    toggleProgreso();

    let totalArticulos = articulosPSEdit.length

    const URL_PROCESOS_SALIDA_EDIT = `${process.env.REACT_APP_URL_PROCESOS_SALIDA}/${idProcesosSalidaHab}`;
    try {
      await axios
        .patch(
          URL_PROCESOS_SALIDA_EDIT,
          {
            totalPiezas,
            totalGeneral: precio * totalPiezas,
            proveedores: idProveedorHab,
            precio
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {

          articulosPSEdit.map((a) => {
            axios
              .patch(
                `${URL_ARTICULOS_PROCESOS_SALIDA}/${a.id}`,
                {
                  cantidadSalida: parseFloat(a.cantidadSalida),
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Editar Cortes",
                        detalle: `${numero_corte}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  function jalaHabilitacion(
    id,
    numero,
    catalogosProcesosName,
    proveedoresName,
    idProveedor,
    pedido,
    idPedido,
    fechaCompromiso,
    totalPiezas,
    precio,
    totalGeneral,
    fecha,
    idCorte,
    numeroCorte
  ) {
    setIdProcesosSalidaHab(id);
    setIdCorteHab(idCorte);
    setIdPedidoHab(idPedido);
    setIdProveedorHab(idProveedor);
    setArticulosPSEdit([])


    axios
      .get(`${URL_DETALLE_HABILITACION_CORTES}/proceso/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPSEdit = response.data;
        let arrayTabla = allArticulosPSEdit
          .map((a) => {
            return {
              id: a._id,
              habilitacion: a.habilitacion[0].nombre + " " + a.habilitacion[0].descripcion,
              cantidad: a.cantidad,
              precio: a.precio,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setArticulosPSEdit(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });


    toggleHabilitacion()
  }

  function saveHabilitacion(event) {
    event.preventDefault();
    let totalRollos = inputFieldsHabilitacion.length + articulosPSEdit.length;

    axios
      .post(
        URL_HABILITACION_CORTES,
        {
          cortes: idCorteHab,
          pedidos: idPedidoHab,
          proveedores: idProveedorHab,
          procesosSalida: idProcesosSalidaHab,
          fecha: fechaHab,
          total: totalHabilitacion,
          saldo: totalHabilitacion,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((data2) => {
        inputFieldsHabilitacion.map((a) => {
          if (a.cantidad != 0) {
            axios
              .post(
                URL_DETALLE_HABILITACION_CORTES,
                {
                  cantidad: a.cantidad,
                  habilitacionCortes: data2.data._id,
                  cortes: idCorteHab,
                  pedidos: idPedidoHab,
                  proveedores: idProveedorHab,
                  procesosSalida: idProcesosSalidaHab,
                  habilitacion: a.idHabilitacion,
                  precio: a.precio
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Salida de Habilitacion",
                        detalle: `Pedido ${idPedidoHab}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              });
          } else {
            totalRollos = totalRollos - 1;
            if (totalRollos == 0) {
              axios
                .post(
                  URL_LOGS,
                  {
                    tipo: "Salida de Habilitacion",
                    detalle: `Pedido ${idPedidoHab}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            }
          }
        });




        articulosPSEdit.map((a) => {
          axios
            .patch(
              `${URL_DETALLE_HABILITACION_CORTES}/${a.id}`,
              {
                cantidad: parseFloat(a.cantidad),
                precio: parseFloat(a.precio),
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem(
                    "app_token"
                  )}`,
                },
              }
            )
            .then(() => {
              totalRollos = totalRollos - 1;
              if (totalRollos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Editar Habilitacion Corte",
                      detalle: `${numero_corte}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        });



      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
        setValidaBoton(true);
      });
  }

  const handleChangeInputHabilitacionHab = (id, selectedArticulo) => {
    const newInputFieldsHabilitacion = inputFieldsHabilitacion.map((i) => {
      if (id === i.id) {
        i.idHabilitacion = selectedArticulo._id;
        i.nombre = selectedArticulo.junta;
        i.precio = selectedArticulo.costo
      }
      return i;
    });
    setInputFieldsHabilitacion(newInputFieldsHabilitacion);
  };

  const handleChangeInputHabilitacion = (id, event) => {
    const newInputFieldsHabilitacion = inputFieldsHabilitacion.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsHabilitacion(newInputFieldsHabilitacion);
    ActualizaTotalesHabilitacion()
  };

  const handleChangeInputHabilitacionExistentes = (id, event) => {
    const newartPSEdit = articulosPSEdit.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setArticulosPSEdit(newartPSEdit);
    ActualizaTotalesHabilitacion()
  };

  function BuscaHabilitacion(id, event) {
    habilitacion.map((a) => {
      if (a._id == event.target.value) {
        let idHabilitacion = a._id;
        let precio = a.costo;
        handleChangeInputHabilitacionMaterial(
          id,
          idHabilitacion,
          precio,
        );
      }
    });
  }

  const handleChangeInputHabilitacionMaterial = (
    id,
    idHabilitacion,
    precio,
  ) => {
    const newInputFieldsHabilitacion = inputFieldsHabilitacion.map((i) => {
      if (id === i.id) {
        i.idHabilitacion = idHabilitacion;
        i.precio = precio;
      }
      return i;
    });
    setInputFieldsHabilitacion(newInputFieldsHabilitacion);
  };

  const handleRemoveFieldsHabilitaccion = (id) => {
    const values = [...inputFieldsHabilitacion];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsHabilitacion(values);
  };

  const handleAddFieldsHabilitacion = () => {
    setInputFieldsHabilitacion([
      ...inputFieldsHabilitacion,
      {
        id: uuidv4(),
        idHabilitacion: "",
        cantidad: 0,
        precio: 0
      },
    ]);
  };
  function cerrarProcesosEntrada(procesosSalida, faltantes, maquilero) {
    if (faltantes > 0) {
      setCantidadFaltante(faltantes);
      setProcesoSalidaFaltante(procesosSalida);
      setIdMaquileroFaltante(maquilero);
      setTotalFaltante(0);
      toggleFaltante();
    } else {
      Swal.fire({
        title: "Estas seguro?",
        text: "Se cerrara el proceso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, cerrar!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .patch(
              `${URL_PROCESOS_SALIDA}/${procesosSalida}`,
              {
                status: "Cerrado",
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then((response) => {
              axios
                .post(
                  URL_LOGS,
                  {
                    tipo: "Cerrar Proceso",
                    detalle: `Proceso ${procesosSalida}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Se cerro",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        }
      });
    }
  }

  function saveFaltantes(event) {
    event.preventDefault();
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cerrara el proceso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, cerrar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            `${URL_PROCESOS_SALIDA}/${procesoSalidaFaltante}`,
            {
              status: "Cerrado",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            axios
              .post(
                URL_FALTANTES_PROVEEDORES,
                {
                  fecha: fechaFaltante,
                  procesosSalida: procesoSalidaFaltante,
                  cantidad: cantidadFaltante,
                  precio: precioFaltante,
                  total: totalFaltante,
                  proveedores: idMaquileroFaltante,
                  utilizado: 0,
                  saldo: totalFaltante,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Cerrar Proceso",
                      detalle: `Proceso ${procesoSalidaFaltante}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: "Se cerro",
                      showConfirmButton: false,
                      timer: 2000,
                    });
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function entradaAlmacen(id, numero) {
    axios
      .get(`${URL_ARTICULOS_CORTES}/corte/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrdenCorte = response.data;
        let arrayTabla = allArticulosOrdenCorte
          .map((a) => {
            return {
              id: a._id,
              articuloNombre:
              a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0].articulosPadre[0]
              .codigo +
            " / " +
            a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0].articulosPadre[0]
              .codigoCliente +
              " / " +
              a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]
                .nombre,
              idArticulo:
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]._id,
              cantidad: a.faltanteRecibir,
              catalogoProcesos:
                a.articulosOrdenesCorte[0].articulosPedido[0].articulos[0]
                  .catalogoProcesos,
              idTalla:
                a.articulosOrdenesCorte[0].articulosPedido[0].tallas[0]._id,
              talla:
                a.articulosOrdenesCorte[0].articulosPedido[0].tallas[0].name,
              idColor:
                a.articulosOrdenesCorte[0].articulosPedido[0].colores[0]._id,
              color:
                a.articulosOrdenesCorte[0].articulosPedido[0].colores[0].name,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const data = Object.values(arrayTabla);
        setArticulosCortesEntrada(data);

        let cantTotal = data.map((c) => parseFloat(c.cantidad));
        let TC = cantTotal.reduce((t, total, index) => t + total, 0);
        setTotalPiezasEntradaAlma(TC);

        let agrupado = data.reduce(function (groups, item) {
          const val = item["idArticulo"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
          };
          groups[val].idArticulo = item.idArticulo;
          return groups;
        }, []);
        let dataFinalAgru = Object.values(agrupado);
        setArticulosUnicosEntradaAlma(dataFinalAgru);
      })
      .catch((err) => {
        console.log(err);
      });

    setSelectedCorte(id);
    setSelectedCorteNumero(numero);
    toggleEntradaAlmacen();
  }

  const handleChangeInputCantidadEntradaAlma = (id, event) => {
    const newArticulosCortesEntrada = articulosCortesEntrada.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
      }
      return i;
    });
    setArticulosCortesEntrada(newArticulosCortesEntrada);
    ActualizaTotalesEntradaAlma();
  };

  function ActualizaTotalesEntradaAlma() {
    let cantTotal = articulosCortesEntrada.map((c) => parseFloat(c.cantidad));
    let TC = cantTotal.reduce((t, total, index) => t + total, 0);
    setTotalPiezasEntradaAlma(TC);
  }

  const saveEntradaAlma = async (event) => {
    event.preventDefault();
    if (fechaEntradaAlma != "") {
      setValidaBoton(false);
      toggleProgreso();

      let totalRollos = articulosCortesEntrada.length;
      try {
        await axios
          .post(
            URL_ENTRADAS_ALMACEN,
            {
              fecha: fechaEntradaAlma,
              cortes: selectedCorte,
              observaciones,
              total_cantidad: totalPiezasEntradaAlma,
              areas: areasEntrada,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            articulosCortesEntrada.map((a) => {
              if (a.cantidad != 0) {
                axios
                  .post(
                    URL_ENTRADAS_ALMACEN_ACUMULADOS,
                    {
                      fecha: fechaEntradaAlma,
                      cortes: selectedCorte,
                      cantidad: a.cantidad,
                      entradasAlmacen: data.data._id,
                      articulosCortes: a.id,
                      articulos: a.idArticulo,
                      tallas: a.idTalla,
                      colores: a.idColor,
                      areas: areasEntrada,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalRollos = totalRollos - 1;
                    if (totalRollos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Entrada Almacen",
                            detalle: `Corte ${selectedCorte}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  });
              } else {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Entrada Almacen",
                        detalle: `Corte ${selectedCorte}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              }
            });
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  // PDF Procesos

  function PDFProcesosSalida(id, numero) {
    axios
      .get(`${URL_PROCESOS_SALIDA}/corte/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrdenCorte = response.data;
        let arrayTabla = allArticulosOrdenCorte
          .sort((a, b) => (a.idProcesosSalida < b.idProcesosSalida ? 1 : -1))
          .map((a) => {
            return {
              id: a._id,
              catalogosProcesosName: a.catalogoProcesos[0].name,
              proveedoresName: a.proveedores[0].nombre_comercial,
              proveedoresDireccion: a.proveedores[0].calle + " " + a.proveedores[0].numero_ext  + " " + a.proveedores[0].numero_int,
              proveedoresDireccion2: a.proveedores[0].estado + " , " + a.proveedores[0].ciudad  + " , " + a.proveedores[0].numero_int,
              idProveedor: a.proveedores[0]._id,
              pedido: a.pedidos[0].idPedido,
              idPedido: a.pedidos[0]._id,
              fechaCompromiso: a.fechaCompromiso,
              totalGeneral: a.totalGeneral,
              totalPiezas: a.totalPiezas,
              precio: a.precio,
              numero: a.idProcesosSalida,
              fecha: a.fecha,
              idCorte: a.cortes[0]._id,
              numeroCorte: a.cortes[0].idCorte,
              numeroOP: a.cortes[0].ordenesCorte[0].numeroOP,
              articulo: a.articulos[0].articulosPadre[0].codigo + " / " + a.articulos[0].articulosPadre[0].nombre + " / " + a.articulos[0].nombre
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const data = Object.values(arrayTabla);
        setPDFPS(data);
      })
      .catch((err) => {
        console.log(err);
      });
    togglePDFPS();
  }

  async function PDFOCPS(
    id,
    numero,
    proceso,
    maquilero,
    maqDir,
    maqDir2,
    pedido,
    fechaCompromiso,
    totalPiezas,
    precio,
    totalGeneral,
    fecha,
    numeroOP
  ) {
    await axios
      .get(`${URL_ARTICULOS_PROCESOS_SALIDA}/proceso/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then(async (response) => {
        let allArticulosCorte = response.data;
        let arrayTabla = allArticulosCorte
          .map((a) => {
            return {
              idArticulo: a.articulosPedido[0].articulos[0]._id,
              articulo: a.articulosPedido[0].articulos[0].articulosPadre[0].codigo + " / " + a.articulosPedido[0].articulos[0].articulosPadre[0].codigoCliente +
                " " +
                a.articulosPedido[0].articulos[0].nombre,
              // a.articulosPedido[0].colores[0].name,
              // a.articulosPedido[0].tallas[0].name,
              precio: a.procesosSalida[0].precio,
              cantidad: a.cantidadSalida,
            };
          })
          .filter(function (el) {
            return el != null;
          });

          let agrupado = arrayTabla.reduce(function (groups, item) {
            const val = item["idArticulo"]
            groups[val] = groups[val] || {
              idArticulo: item.idArticulo,
              cantidad: 0
            };
            groups[val].cantidad += item.cantidad;
            groups[val].articulo = item.articulo;
            groups[val].precio = item.precio;
            return groups;
          }, []);
  
          let agrupadoValues = Object.values(agrupado);

          const data = agrupadoValues.map((a) => {
            return [
              a.articulo,
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precio),
              new Intl.NumberFormat("en-US").format(a.cantidad),
            ]
        });

        const dataPDF = Object.values(data);

        await axios
          .get(`${URL_DETALLE_HABILITACION_CORTES}/proceso/${id}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then((hab) => {
            let allHabilitacionCorte = hab.data;
            let totalHab = 0
            let totalTotal = 0
            let arrayTablaHab = allHabilitacionCorte
              .map((a) => {
                totalHab = a.cantidad * a.precio
                totalTotal = totalTotal + totalHab
                return [
                  a.habilitacionCortes[0].fecha,
                  a.habilitacion[0].nombre +
                    " " +
                    a.habilitacion[0].descripcion,
                  a.cantidad,
                  new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precio),
                  new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalHab)
                ];
              })
              .filter(function (el) {
                return el != null;
              });

            const dataPDFHab = Object.values(arrayTablaHab);

            const doc = new jsPDF();
            var img = new Image();
            img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
            img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
            doc.addImage(img, "png", 150, 10, 35, 10);
            var img2 = new Image();
            img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
            doc.addImage(img2, "png", 90, 15, 30, 30);
            doc.setFontSize(12);
            doc.text(`Maquilero ${maquilero}`, 20, 35);
            doc.text(`${maqDir}`, 20, 40);
            doc.text(`${maqDir2}`, 20, 45);
            doc.text(`Comercializadora Guibor SA de CV`, 125, 30);
            doc.text(`Ferrocarril de Acambaro #4 Bodega 6`, 125, 40);
            doc.text(`Alce Blanco, Naucalpan de Juárez`, 125, 45);
            doc.text(`CP: 53370`, 125, 50);
            doc.text(`OP: ${numeroOP}`, 20, 25);
            doc.text(`Fecha: ${fecha}`, 20, 30);
            doc.autoTable({
              head: [["Articulo", "Precio", "Cantidad"]],
              body: dataPDF,
              startY: 55,
              foot: [
                // [
                //   "",
                //   "",
                //   "Total Piezas",
                //   new Intl.NumberFormat("en-US").format(totalPiezas)
                // ],
                [
                  // "",
                  "",
                  "Importe Total",
                  new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalGeneral)
                ],
              ],
              showFoot: "lastPage",
            });
            doc.text(`Habilitacion`, 15, doc.autoTable.previous.finalY + 10);
            doc.autoTable({
              head: [["Fecha", "Descripcion", "Cantidad", "Precio", "Total"]],
              body: dataPDFHab,
              startY: doc.autoTable.previous.finalY + 13,
              foot: [
                [
                  // "",
                  // "",
                  "Total",
                  new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotal),
                ],
              ],
              showFoot: "lastPage",
            });
            doc.text(`Total`, 15, doc.autoTable.previous.finalY + 10);
            doc.autoTable({
              head: [["Maquila", "Habilitacion", "Total"]],
              // body: dataPDFHab,
              startY: doc.autoTable.previous.finalY + 13,
              foot: [
                [
                  new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalGeneral),
                  new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotal),
                  new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalGeneral -  totalTotal),
                ],
              ],
              showFoot: "lastPage",
            });
            doc.text(`___________________`, 35, doc.autoTable.previous.finalY + 20);
            doc.text(`Recibio`, 50, doc.autoTable.previous.finalY + 25);
            doc.text(`___________________`, 85, doc.autoTable.previous.finalY + 20);
            doc.text(`Entrego`, 100, doc.autoTable.previous.finalY + 25);
            doc.text(`___________________`, 135, doc.autoTable.previous.finalY + 20);
            doc.text(`Reviso`, 150, doc.autoTable.previous.finalY + 25);
            doc.save(`ProcesoSalida-${numero}.pdf`);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function PDFProcesosEntrada(id, numero) {
    axios
      .get(`${URL_PROCESOS_ENTRADA}/corte/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosOrdenCorte = response.data;
        let arrayTabla = allArticulosOrdenCorte
          .sort((a, b) => (a.idProcesosEntrada < b.idProcesosEntrada ? 1 : -1))
          .map((a) => {
            return {
              id: a._id,
              catalogosProcesosName: a.catalogoProcesos[0].name,
              proveedoresName: a.proveedores[0].nombre_comercial,
              proveedoresDireccion: a.proveedores[0].calle + " " + a.proveedores[0].numero_ext  + " " + a.proveedores[0].numero_int,
              proveedoresDireccion2: a.proveedores[0].estado + " , " + a.proveedores[0].ciudad  + " , " + a.proveedores[0].numero_int,
              pedido: a.pedidos[0].idPedido,
              totalGeneral: a.totalGeneral,
              totalPiezas: a.totalPiezas,
              precio: a.precio,
              numero: a.idProcesosEntrada,
              fecha: a.fecha,
              articulo: a.procesosSalida[0].articulos[0].articulosPadre[0].codigo + " / " + a.procesosSalida[0].articulos[0].articulosPadre[0].codigoCliente + " / " + a.procesosSalida[0].articulos[0].articulosPadre[0].nombre
            };
          })
          .filter(function (el) {
            return el != null;
          });

        const data = Object.values(arrayTabla);
        setPDFPE(data);
      })
      .catch((err) => {
        console.log(err);
      });
    togglePDFPE();
  }

  async function PDFOCPE(
    id,
    numero,
    proceso,
    maquilero,
    totalPiezas,
    precio,
    fecha,
    totalGeneral
  ) {
    await axios
      .get(`${URL_ARTICULOS_PROCESOS_ENTRADA}/proceso/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosCorte = response.data;
        let arrayTabla = allArticulosCorte
          .map((a) => {
            return [
              a.articulosPedido[0].articulos[0].articulosPadre[0].codigo + " / " + a.articulosPedido[0].articulos[0].articulosPadre[0].codigoCliente +
                " " +
                a.articulosPedido[0].articulos[0].nombre,
              a.articulosPedido[0].colores[0].name,
              a.articulosPedido[0].tallas[0].name,
              a.articulosProcesosSalida[0].cantidadSalida,
              a.cantidadEntrada,
            ];
          })
          .filter(function (el) {
            return el != null;
          });

        const dataPDF = Object.values(arrayTabla);

        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 60, 25);
        doc.text(`Maquilero ${maquilero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Proceso Entrada ${proceso}`, 20, 30);
        doc.text(`Precio $${precio}`, 20, 35);
        doc.text(
          `Total ${new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
          }).format(totalGeneral)}`,
          20,
          40
        );
        doc.autoTable({
          head: [["Articulo", "Color", "Talla", "Cantidad"]],
          body: dataPDF,
          startY: 45,
          foot: [
            [
              "",
              "",
              "Total",
              new Intl.NumberFormat("en-US").format(totalPiezas),
            ],
          ],
          showFoot: "lastPage",
        });
        doc.save(`ProcesoEntrada-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const options = habilitacion.map((option) => {
    const junta = option.nombre + " " + option.descripcion;
    const firstLetter = option.nombre[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_produccion ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {/* {user.menu_produccion ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/CortesCreate"
                >
                  Nuevo Corte
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Corte
                </Button>
              )} */}
              <Button
                size="sm"
                href="/MenuProduccion"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Produccion</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Listado Produccion</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
                    <Label>Asignado</Label>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedProduccion}
                      onChange={(e) => {
                        setSelectedProduccion(e.target.value);
                        setCurrentPage(1);
                      }}
                    >
                      <option value="">Selecciona</option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Label>Linea</Label>
                    <Input
                  bsSize="sm"
                  type="select"
                  value={selectedLinea}
                  onChange={(e) => {
                    setSelectedLinea(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {lineas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
                  </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr style={ 
                      a.produccion =="No" ?
                      { backgroundColor: "#ed8c8c" } :
                      null
                    }>
                      <td>{a.numeroOP}</td>
                      <td>{a.nombreArticulo}</td>
                      <td>{a.total_cantidad}</td>
                      {/* <td>{a.numero}</td> */}
                      <td>{a.fecha}</td>
                      {/* <td>{a.ordenCorte}</td> */}
                      {/* <td>{a.mesa}</td> */}
                      {/* <td>{a.fechaCancelacionPedido}</td> */}
                      <td>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={(e) =>
                            PDFOC(
                              a.numero,
                              a.fecha,
                              a.ordenCorte,
                              a.mesa,
                              a.total_cantidad,
                              a._id,
                              a.lienzos,
                              a.largoTrazo,
                              a.tiempoInicio,
                              a.tiempoFin,
                              a.responsable1,
                              a.responsable2,
                              a.numeroOP
                            )
                          }
                        >
                          <i class="far fa-file-pdf"></i>
                        </Button>
                      </td>
             { a.status == "Abierto"?(
                <>
                     <td>
                        <Button
                          color="info"
                          id="Telas"
                          size="sm"
                          onClick={(e) => procesosSalida(a._id, a.numero)}
                        >
                          <i class="fas fa-sign-out-alt"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={(e) => PDFProcesosSalida(a._id, a.numero)}
                        >
                          <i class="fas fa-search"></i>
                        </Button>
                      </td>
                      <td>
                        <Button
                          color="primary"
                          id="Telas"
                          size="sm"
                          onClick={(e) => procesosEntrada(a._id, a.numero)}
                        >
                          <i class="fas fa-sign-in-alt"></i>
                        </Button>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={(e) => PDFProcesosEntrada(a._id, a.numero)}
                        >
                          <i class="fas fa-search"></i>
                        </Button>
                      </td>
                      {/* <td>
                        <Button
                          size="sm"
                          className="btn btn-success"
                          onClick={(e) => entradaAlmacen(a._id, a.numero)}
                        >
                          <i class="fas fa-tshirt"></i>
                        </Button>
                      </td> */}
                      </>):(
                        <>
                        <td></td>
                        <td></td>
                        </>
                      )}
                      <td>
                      <Status
                              idStatus={a._id}
                              status={a.status}
                              URL_STATUS={process.env.REACT_APP_URL_CORTES}
                            />
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios minutos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal
        size="xxl"
        isOpen={modalProcesosSalida}
        toggle={toggleProcesosSalida}
      >
        <ModalHeader toggle={toggleProcesosSalida}>
          <h4>Procesos Corte {selectedCorteNumero}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Articulo</Label>
            </Col>
            <Col md={1}>
              <Label className="mr-sm-2">Cantidad</Label>
            </Col>
            <Col md={8}>
              <Label className="mr-sm-2">Procesos</Label>
            </Col>
          </Row>
          {articulosUnicos.map((a) => (
            <div key={a.id}>
              <Row>
                <Col md={3}>
                  <Input
                    bsSize="sm"
                    name="articulos"
                    type="text"
                    value={a.articuloNombre}
                    disabled
                  />
                </Col>
                <Col md={1}>
                  <Input
                    bsSize="sm"
                    name="articulos"
                    type="text"
                    value={a.total_cantidad}
                    disabled
                  />
                </Col>
                <Col md={8}>
                  {a.catalogoProcesos.map((p) => (
                    <Button
                      size="sm"
                      className="btn btn-success"
                      onClick={(e) =>
                        funcionProcesosSalida(
                          p._id,
                          p.name,
                          a.idArticulo,
                          a.articuloNombre,
                          a.idPedido,
                          a.numeroPedido
                        )
                      }
                    >
                      {p.name}
                    </Button>
                  ))}
                </Col>
              </Row>
            </div>
          ))}
        </ModalBody>
      </Modal>

      <Modal
        size="xl"
        isOpen={modalFuncionProcesosSalida}
        toggle={toggleFuncionProcesosSalida}
      >
        <ModalHeader toggle={toggleFuncionProcesosSalida}>
          <h4>
            Proceso Salida: {nombreProcesosSalida} <br />
            Articulo: {nombreArticulo} <br />
            Pedido: {numeroPedido}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={saveSalidaProceso}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fechaProceso}
                  required
                  onChange={(e) => {
                    setFechaProceso(e.target.value);
                  }}
                />
              </Col>
              {/* <Col md={3}>
                <Label>Fecha Compromiso</Label>
                <Input
                  type="date"
                  placeholder="FechaCompromiso"
                  value={fechaCompromisoProceso}
                  required
                  onChange={(e) => {
                    setFechaCompromisoProceso(e.target.value);
                  }}
                />
              </Col> */}
              <Col md={3}>
                <Label>Maquilero</Label>
                <Input
                  type="select"
                  placeholder="Maquilero"
                  value={maquileroProceso}
                  required
                  onChange={(e) => {
                    setMaquileroProceso(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      if (a.tipo == "Maquilero" && a.is_active == "Si") {
                        return (
                          <option value={a._id}>{a.nombre_comercial}</option>
                        );
                      }
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Precio</Label>
                <Input
                  // bsSize="sm"
                  name="precio"
                  type="number"
                  placeholder="Precio"
                  value={precioProceso}
                  required
                  onChange={(event) => handleChangeInputPrecio(event)}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={12}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={4}>
                <Label className="mr-sm-2">Color</Label>
              </Col>
              <Col md={4}>
                <Label className="mr-sm-2">Talla</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>
            {inputFields
        .sort(
          (a, b) =>{
            // Convertir las tallas de cadena a número antes de comparar
            const tallasA = parseInt(a.tallas);
            const tallasB = parseInt(b.tallas);
            
            // Ordenar primero por 'colores' y luego por 'tallas'
            return a.colores.localeCompare(b.colores) || tallasA - tallasB;
          })
        .map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="color"
                      type="text"
                      value={inputField.colores}
                      disabled
                    />
                  </Col>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="talla"
                      type="text"
                      value={inputField.tallas}
                      disabled
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      value={inputField.cantidad}
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      value={inputField.total}
                      disabled
                    />
                  </Col>
                </Row>
              </div>
            ))}
            <br />
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                <h4>TOTALES</h4>
              </Col>
              <Col md={2}>
                <h4>{totalPiezas}</h4>
              </Col>
              <Col md={2}>
                <h4>{"$" + new Intl.NumberFormat("en-US").format(totalGeneral)}</h4>
              </Col>
            </Row>

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>

      {/* Procesos Entrada */}
      <Modal
        size="xxl"
        isOpen={modalProcesosEntrada}
        toggle={toggleProcesosEntrada}
      >
        <ModalHeader toggle={toggleProcesosEntrada}>
          <h4>Procesos Corte {selectedCorteNumero}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={2}>
              <Label className="negrita">Articulo</Label>
            </Col>
            <Col md={1}>
              <Label className="negrita">Proceso</Label>
            </Col>
            <Col md={2}>
              <Label className="negrita">Maquilero</Label>
            </Col>
            <Col md={1}>
              <Label className="negrita">Fecha Salida</Label>
            </Col>
            <Col md={1}>
              <Label className="negrita">Compromiso</Label>
            </Col>
            <Col md={1}>
              <Label className="negrita">Precio</Label>
            </Col>
            <Col md={1}>
              <Label className="negrita">Salida</Label>
            </Col>
            <Col md={1}>
              <Label className="negrita">Entrada</Label>
            </Col>
            <Col md={1}>
              <Label className="negrita">Recibir</Label>
            </Col>
            <Col md={1}>
              <Label className="negrita">Cerrar</Label>
            </Col>
          </Row>
          {articulosUnicosEntrada.map((a) => (
            <div key={a.id}>
              <Row>
                <Col md={2}>
                  {" "}
                  <Label> {a.articuloNombre}</Label>
                </Col>
                <Col md={1}>
                  {" "}
                  <Label>{a.catalogoProcesos}</Label>
                </Col>
                <Col md={2}>
                  {" "}
                  <Label>{a.maquilero}</Label>
                </Col>
                <Col md={1}>
                  {" "}
                  <Label>{a.fecha}</Label>
                </Col>
                <Col md={1}>
                  {" "}
                  <Label>{a.fechaCompromiso}</Label>
                </Col>
                <Col md={1}>
                  {" "}
                  <Label>$ {a.precio}</Label>
                </Col>
                <Col md={1}>
                  {" "}
                  <Label>{a.cantidadSalida}</Label>
                </Col>
                <Col md={1}>
                  {" "}
                  <Label>{a.cantidadEntrada}</Label>
                </Col>
                <Col md={1}>
                  {a.status == "Cerrado" || a.faltantes <= 0 ? undefined : (
                    <Button
                      color="primary"
                      size="sm"
                      className="btn btn-success"
                      onClick={(e) =>
                        funcionProcesosEntrada(
                          a.idCatalogoProcesos,
                          a.catalogoProcesos,
                          a.idArticulo,
                          a.articuloNombre,
                          a.idPedido,
                          a.numeroPedido,
                          a.procesosSalida
                        )
                      }
                    >
                      <i class="fas fa-sign-in-alt"></i>
                    </Button>
                  )}
                </Col>
                <Col md={1}>
                  {a.status == "Cerrado" ? undefined : (
                    <Button
                      color="primary"
                      size="sm"
                      className="btn btn-success"
                      onClick={(e) =>
                        cerrarProcesosEntrada(
                          a.procesosSalida,
                          a.faltantes,
                          a.idMaquilero
                        )
                      }
                    >
                      <i class="fas fa-lock"></i>
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          ))}
        </ModalBody>
      </Modal>

      <Modal
        size="xl"
        isOpen={modalFuncionProcesosEntrada}
        toggle={toggleFuncionProcesosEntrada}
      >
        <ModalHeader toggle={toggleFuncionProcesosEntrada}>
          <h4>
            Proceso: {nombreProcesosEntrada} <br />
            Articulo: {nombreArticulo} <br />
            Pedido: {numeroPedido}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={saveEntradaProceso}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fechaProceso}
                  required
                  onChange={(e) => {
                    setFechaProceso(e.target.value);
                  }}
                />
              </Col>

              <Col md={3}>
                <Label>Maquilero</Label>
                <Input
                  type="select"
                  placeholder="Maquilero"
                  value={maquileroProceso}
                  required
                  disabled
                >
                  <option value="">Selecciona</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      if (a.tipo == "Maquilero") {
                        return (
                          <option value={a._id}>{a.nombre_comercial}</option>
                        );
                      }
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Precio</Label>
                <Input
                  // bsSize="sm"
                  name="precio"
                  type="number"
                  placeholder="Precio"
                  value={precioProceso}
                  required
                  onChange={(event) => handleChangeInputPrecio(event)}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={12}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={4}>
                <Label className="mr-sm-2">Color</Label>
              </Col>
              <Col md={4}>
                <Label className="mr-sm-2">Talla</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>
            {inputFields
            .sort(
              (a, b) =>{
                // Convertir las tallas de cadena a número antes de comparar
                const tallasA = parseInt(a.tallas);
                const tallasB = parseInt(b.tallas);
                
                // Ordenar primero por 'colores' y luego por 'tallas'
                return a.colores.localeCompare(b.colores) || tallasA - tallasB;
              })
              .map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="color"
                      type="text"
                      value={inputField.colores}
                      disabled
                    />
                  </Col>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="talla"
                      type="text"
                      value={inputField.tallas}
                      disabled
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      value={inputField.cantidad}
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
                      value={inputField.total}
                      disabled
                    />
                  </Col>
                </Row>
              </div>
            ))}
            <br />
            <Row>
              <Col md={4}></Col>
              <Col md={4}>
                <h4>TOTALES</h4>
              </Col>
              <Col md={2}>
                <h4>{totalPiezas}</h4>
              </Col>
              <Col md={2}>
              <h4>{"$" + new Intl.NumberFormat("en-US").format(totalGeneral)}</h4>
              </Col>
            </Row>
            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalFaltante} toggle={toggleFaltante}>
        <ModalHeader toggle={toggleFaltante}></ModalHeader>
        <ModalBody>
          <Form onSubmit={saveFaltantes}>
            <Row>
              <Col md={4}>
                <Label>Fecha</Label>
                <Input
                  bsSize="sm"
                  name="fecha"
                  type="date"
                  required
                  value={fechaFaltante}
                  onChange={(event) => {
                    setFechaFaltante(event.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={4}>
                <Label>Faltantes</Label>
                <Input
                  bsSize="sm"
                  name="cantidad"
                  type="number"
                  value={cantidadFaltante}
                  onChange={(event) => {
                    setCantidadFaltante(event.target.value);
                    setTotalFaltante(event.target.value * precioFaltante);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Precio</Label>
                <Input
                  bsSize="sm"
                  name="precio"
                  type="number"
                  value={precioFaltante}
                  onChange={(event) => {
                    setPrecioFaltante(event.target.value);
                    setTotalFaltante(event.target.value * cantidadFaltante);
                  }}
                />
              </Col>
              <Col md={4}>
                <Label>Total</Label>
                <Input
                  bsSize="sm"
                  name="cantidad"
                  type="number"
                  value={totalFaltante}
                  disabled
                />
              </Col>
            </Row>
            <br />

            <Button type="submit" className="btn btn-success">
              Guardar
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      {/* Entrada Almacen */}

      <Modal
        size="xl"
        isOpen={modalEntradaAlmacen}
        toggle={toggleEntradaAlmacen}
      >
        <ModalHeader toggle={toggleEntradaAlmacen}></ModalHeader>
        <ModalBody>
          <Form onSubmit={saveEntradaAlma}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fechaEntradaAlma}
                  required
                  onChange={(e) => {
                    setFechaEntradaAlma(e.target.value);
                  }}
                />
              </Col>

              <Col md={3}>
                <Label>Almacen</Label>
                <Input
                  type="select"
                  value={areasEntrada}
                  required
                  onChange={(e) => {
                    setAreasEntrada(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {areas.map((a) => {
                    if (a.name != "General") {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
                </Input>
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={12}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={4}>
                <Label className="mr-sm-2">Articulo</Label>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Color</Label>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Talla</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
            </Row>
            {articulosCortesEntrada.map((a) => (
              <div key={a.id}>
                <Row>
                  <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="articuloNombre"
                      type="text"
                      value={a.articuloNombre}
                      disabled
                    />
                  </Col>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="color"
                      type="text"
                      value={a.color}
                      disabled
                    />
                  </Col>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="talla"
                      type="text"
                      value={a.talla}
                      disabled
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      value={a.cantidad}
                      onChange={(event) =>
                        handleChangeInputCantidadEntradaAlma(a.id, event)
                      }
                    />
                  </Col>
                </Row>
              </div>
            ))}
            <br />
            <Row>
              <Col md={7}></Col>
              <Col md={3}>
                <h4>TOTAL</h4>
              </Col>
              <Col md={2}>
                <h4>{totalPiezasEntradaAlma}</h4>
              </Col>
            </Row>

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>

      {/* PDFPS */}
      <Modal size="xxl" isOpen={modalPDFPS} toggle={togglePDFPS}>
        <ModalHeader toggle={togglePDFPS}>
          <h3>Detalle Salida Procesos</h3>
        </ModalHeader>
        <ModalBody>
          <Table>
            <tr>
              <th>#</th>
              <th>Proceso</th>
              <th>Proveedor</th>
              <th>Articulo</th>
              <th>Pedido</th>
              <th>Fecha Comp.</th>
              <th>Piezas</th>
              <th>Precio</th>
              <th>Total</th>
              <th>Editar</th>
              <th>Habilitacion</th>
              <th>PDF</th>
            </tr>
            {PDFPS.map((a) => {
              return (
                <tr>
                  <td>{a.numero}</td>
                  <td>{a.catalogosProcesosName}</td>
                  <td>{a.proveedoresName}</td>
                  <td>{a.articulo}</td>
                  <td>{a.pedido}</td>
                  <td>{a.fechaCompromiso}</td>
                  <td>{a.totalPiezas}</td>
                  <td>{a.precio}</td>
                  <td>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 2,
                    }).format(a.totalGeneral)}
                  </td>
                  <td>
                  <Button
                                    color="info"
                                    id="Editar"
                                    size="sm"
                                    onClick={(e) =>
                                      EditArticulos(
                                        a.id,
                                        a.numero,
                                        a.catalogosProcesosName,
                                        a.proveedoresName,
                                        a.idProveedor,
                                        a.pedido,
                                        a.idPedido,
                                        a.fechaCompromiso,
                                        a.totalPiezas,
                                        a.precio,
                                        a.totalGeneral,
                                        a.fecha,
                                        a.idCorte,
                                        a.numeroCorte
                                      )
                                    }
                                  >
                                    <i class="fas fa-cart-plus"></i>
                                  </Button>
                  </td>
                  <td>
                    <Button
                      size="sm"
                      className="btn"
                      color="success"
                      onClick={(e) =>
                        jalaHabilitacion(
                          a.id,
                          a.numero,
                          a.catalogosProcesosName,
                          a.proveedoresName,
                          a.idProveedor,
                          a.pedido,
                          a.idPedido,
                          a.fechaCompromiso,
                          a.totalPiezas,
                          a.precio,
                          a.totalGeneral,
                          a.fecha,
                          a.idCorte,
                          a.numeroCorte
                        )
                      }
                    >
                      <i class="fas fa-tag"></i>
                    </Button>
                  </td>
                  <td>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={(e) =>
                        PDFOCPS(
                          a.id,
                          a.numero,
                          a.catalogosProcesosName,
                          a.proveedoresName,
                          a.proveedoresDireccion,
                          a.proveedoresDireccion2,
                          a.pedido,
                          a.fechaCompromiso,
                          a.totalPiezas,
                          a.precio,
                          a.totalGeneral,
                          a.fecha,
                          a.numeroOP
                        )
                      }
                    >
                      <i class="far fa-file-pdf"></i>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>
        </ModalBody>
      </Modal>

      <Modal size="xxl" isOpen={modalPDFPE} toggle={togglePDFPE}>
        <ModalHeader toggle={togglePDFPE}>
          <h3>Detalle Entrada Procesos</h3>
        </ModalHeader>
        <ModalBody>
          <Table>
            <tr>
              <th>#</th>
              <th>Proceso</th>
              <th>Proveedor</th>
              <th>Articulo</th>
              <th>Pedido</th>
              <th>Fecha</th>
              <th>Piezas</th>
              <th>Precio</th>
              <th>Total</th>
              <th>PDF</th>
            </tr>
            {PDFPE.map((a) => {
              return (
                <tr>
                  <td>{a.numero}</td>
                  <td>{a.catalogosProcesosName}</td>
                  <td>{a.proveedoresName}</td>
                  <td>{a.articulo}</td>
                  <td>{a.pedido}</td>
                  <td>{a.fecha}</td>
                  <td>{a.totalPiezas}</td>
                  <td>{a.precio}</td>
                  <td>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 2,
                    }).format(a.totalGeneral)}
                  </td>
                  <td>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={(e) =>
                        PDFOCPE(
                          a.id,
                          a.numero,
                          a.catalogosProcesosName,
                          a.proveedoresName,
                          a.totalPiezas,
                          a.precio,
                          a.fecha,
                          a.totalGeneral
                        )
                      }
                    >
                      <i class="far fa-file-pdf"></i>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>
        </ModalBody>
      </Modal>

      <Modal size="xxl" isOpen={modalHabilitacion} toggle={toggleHabilitacion}>
        <ModalHeader toggle={toggleHabilitacion}>
          <h3>Entregar Habilitacion</h3>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={saveHabilitacion}>
            <Row>
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  bsSize="sm"
                  type="date"
                  value={fechaHab}
                  onChange={(event) => {
                    setFechaHab(event.target.value);
                  }}
                ></Input>
              </Col>
            </Row>
            <br />
            <h4>Cantidad Entregada</h4>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Material</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
            </Row>
            {articulosPSEdit.map((a) => (
              <div key={a.id}>
                <Row>
                  
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="nombre"
                      type="string"
                      value={a.habilitacion}
                      disabled
                    ></Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      value={a.cantidad}
                      disabled
                      // onChange={(event) => {
                      //   handleChangeInputHabilitacionExistentes(a.id, event);
                      // }}
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="precio"
                      type="number"
                      value={a.precio}
                      disabled
                      // onChange={(event) => {
                      //   handleChangeInputHabilitacionExistentes(a.id, event);
                      // }}
                    ></Input>
                  </Col>

                  
                </Row>
              </div>
            ))}
            <br />
            <h4>Agregar Nuevos</h4>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Buscar</Label>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Material</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
            </Row>

            {inputFieldsHabilitacion.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Autocomplete
                size="small"
                value={inputField.idHabilitacion}
                onChange={(event, selectedArticulo) => {
                  handleChangeInputHabilitacionHab(inputField.id, selectedArticulo);
                }}
                options={options.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.junta}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecciona"
                    variant="outlined"
                  />
                )}
                renderOption={(option) => (
                  <React.Fragment>
                    {option.nombre}
                  </React.Fragment>
                )}
              />
                  </Col>
                  <Col md={3}>
                    <Input
                      // bsSize="sm"
                      name="nombre"
                      type="string"
                      value={inputField.nombre}
                      disabled
                    ></Input>
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="cantidad"
                      type="number"
                      value={inputField.cantidad}
                      onChange={(event) => {
                        handleChangeInputHabilitacion(inputField.id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="precio"
                      type="number"
                      value={inputField.precio}
                      onChange={(event) => {
                        handleChangeInputHabilitacion(inputField.id, event);
                      }}
                    ></Input>
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFieldsHabilitacion.length === 1}
                      onClick={() =>
                        handleRemoveFieldsHabilitaccion(inputField.id)
                      }
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFieldsHabilitacion}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>


      <Modal size="lg" isOpen={modalEditPS} toggle={toggleEditPS}>
        <ModalHeader toggle={toggleEditPS}>
          <h4>Editar Corte {numero_corte}</h4>
        </ModalHeader>
        <ModalBody>
        <Row>
          <Col md={6}>
            <Label className="mr-sm-2">Maquilero</Label>
          <Input
                  type="select"
                  placeholder="Maquilero"
                  value={idProveedorHab}
                  required
                  onChange={(e) => {
                    setIdProveedorHab(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      if (a.tipo == "Maquilero" && a.is_active == "Si") {
                        return (
                          <option value={a._id}>{a.nombre_comercial}</option>
                        );
                      }
                    })}
                </Input>
          </Col>
          <Col md={3}>
                <Label>Precio</Label>
                <Input
                  // bsSize="sm"
                  name="precio"
                  type="number"
                  placeholder="Precio"
                  value={precio}
                  required
                  onChange={(e) => {
                    setPrecio(parseFloat(e.target.value));
                    ActualizaTotalesEditPS();
                  }}
                />
              </Col>
        </Row>
        <br />
        <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Articulos</Label>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Talla</Label>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Color</Label>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Cantidad</Label>
            </Col>
          </Row>

          {articulosPSEdit.map((ao) => (
            <div key={ao.id}>
              <Row>
                <Col md={3}>
                  <Input
                    bsSize="sm"
                    
                    type="text"
                    value={ao.articulo}
                    required
                    disabled
                  />
                </Col>
                <Col md={2}>
                  <Input
                    bsSize="sm"
                    
                    type="text"
                    value={ao.talla}
                    required
                    disabled
                  />
                </Col>
                <Col md={2}>
                  <Input
                    bsSize="sm"
                    
                    type="text"
                    value={ao.color}
                    required
                    disabled
                  />
                </Col>

                <Col md={2}>
                  <Input
                    bsSize="sm"
                    name="cantidad"
                    type="number"
                    min="0"
                    step="any"
                    placeholder="Cantidad"
                    value={ao.cantidadSalida}
                    required
                    onChange={(event) =>
                      handleChangeInputExistentesCantidad(ao.id, event)
                    }
                  />
                </Col>
              </Row>
            </div>
          ))}
          <Row>
          <Col md={9}>
            <h4 id="logoutBoton">
              TOTAL {new Intl.NumberFormat("en-US").format(totalPiezas)}{" "}
            </h4>
          </Col>
        </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" 
          onClick={saveEditArticulos}
          >
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoCortes;
