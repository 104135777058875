import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Redirect } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab, faFacebook, fas } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
  faHome,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import Password from "../Login/Password";

library.add(
  fab,
  faCoffee,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
  faFacebook,
  faHome,
  faEnvelope,
  faPhoneAlt
);

const Header = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleLogout = () => setDropdownOpen((prevState) => !prevState);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { isAuth, user } = useContext(AuthContext);
  const [modalPassword, setModalPassword] = useState(false);
  const togglePassword = () => setModalPassword(!modalPassword);
  return (
    <>
      {isAuth ? (
        <header className="sticky-top">
          {!user.menu_edoCta_Cliente && !user.menu_cobradores ? (
            <>
            <Navbar color="dark" dark expand="lg">
              <a href="/Inicio">
                <img
                  src={process.env.PUBLIC_URL + "/LogoSistemify2RS.png"}
                  height="50 px"
                  alt="logo"
                />
              </a>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>

                {user.menu_articulos ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuMateriales">
                        MATERIALES
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      MATERIALES
                    </NavLink>
                  )}

                   {user.menu_articulos ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuArticulos">
                        MODELOS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      MODELOS
                    </NavLink>
                  )}
                   
                  {user.menu_compras ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuCompras">
                        COMPRAS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      COMPRAS
                    </NavLink>
                  )}

            {user.menu_produccion ? (
                           <NavItem>
                             <NavLink className="titulosNav" href="/MenuProduccion">
                               PRODUCCION
                             </NavLink>
                           </NavItem>
                         ) : (
                           <NavLink className="titulosNav" color="link" disabled>
                             PRODUCCION
                           </NavLink>
                         )}

               {user.menu_adminCxP ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuCXP">
                        CXP
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      CXP
                    </NavLink>
                  )}

             {/*    {user.menu_ventas ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuVentas">
                        VENTAS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      VENTAS
                    </NavLink>
                  )}
           

                  {user.menu_inventarios ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuInventarios">
                        INVENTARIOS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      INVENTARIOS
                    </NavLink>
                  )} */}
                  {user.menu_nominas ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuNominas">
                        EMPLEADOS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      EMPLEADOS
                    </NavLink>
                  )}
                {/* {user.menu_gastos  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuGastos">
                        GASTOS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      GASTOS
                    </NavLink>
                  )}
                                  
                  {user.menu_admin ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuAdmin">
                        ADMIN
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      ADMIN
                    </NavLink>
                  )}

                {user.menu_reportes ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuReportes">
                        REPORTES
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      REPORTES
                    </NavLink>
                  )} */}

                  {user.menu_usuarios ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuUsuarios">
                        USUARIOS
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      USUARIOS
                    </NavLink>
                  )}
                  {/* {user.menu_usuarios ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuConfiguraciones">
                        CONFIGURACION
                      </NavLink>
                    </NavItem>
                  ) : (
                    <NavLink className="titulosNav" color="link" disabled>
                      CONFIGURACION
                    </NavLink>
                  )} */}
                  {user.menu_logs ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/MenuLogs">
                        LOGS
                      </NavLink>
                    </NavItem>
                  ) : undefined}
                </Nav>
                <NavbarText>
                  <NavLink className="titulosNav">
                    <Dropdown direction="left" isOpen={dropdownOpen} toggle={toggleLogout}>
                      <DropdownToggle color="link">
                        <i class="fas fa-user-cog fa-2x"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem href="/logout">Logout</DropdownItem>
                        <DropdownItem onClick={togglePassword}>Password</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </NavLink>
                </NavbarText>
                <Modal size="sm" isOpen={modalPassword} toggle={togglePassword}>
                  <ModalHeader toggle={togglePassword}>
                    <h4>Cambio de contraseña</h4>
                  </ModalHeader>
                  <ModalBody>
                    <Password />
                  </ModalBody>
                </Modal>
              </Collapse>
            </Navbar>
             </>
          ) : (
            <Navbar color="dark" dark expand="md">
              <a href="/Inicio">
                <img
                  src={process.env.PUBLIC_URL + "/LogoSistemify2RS.png"}
                  height="50 px"
                  alt="logo"
                />
              </a>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                {user.menu_cobradores ? (
                    <NavItem>
                      <NavLink
                        className="titulosNav"
                        href="/AbonosNotasCreate"
                      >
                        Abonos Facturas
                      </NavLink>
                    </NavItem>
                  ) : undefined}
                  {user.menu_edoCta_Fam ? (
                    <NavItem>
                      <NavLink
                        className="titulosNav"
                        href="/EdoCtaVistaFamilias"
                      >
                        ESTADO DE CUENTA
                      </NavLink>
                    </NavItem>
                  ) : undefined}
                   {user.menu_edoCta_Fam ? (
                    <NavItem>
                      <NavLink
                        className="titulosNav"
                        href="/InfoHijos"
                      >
                        INFO ALUMNOS
                      </NavLink>
                    </NavItem>
                  ) : undefined}
                  {user.menu_edoCta_Cliente ? (
                    <NavItem>
                      <NavLink
                        className="titulosNav"
                        href="/EdoCtaVistaClientes"
                      >
                        ESTADO DE CUENTA
                      </NavLink>
                    </NavItem>
                  ) : undefined}
                </Nav>
                <NavbarText>
                  <NavLink className="titulosNav" href="/logout">
                    <i class="fas fa-user-cog fa-2x"></i>
                  </NavLink>
                </NavbarText>
              </Collapse>
            </Navbar>

          )}
                      <h4 align="center">SU CUENTA PRESENTA UN ADEUDO LE SOLICITAMOS RELIZAR EL PAGO A LA BREVEDAD</h4>

        </header>
      ) : undefined}
    </>
  );
};

export default Header;
